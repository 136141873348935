<template>
  <div class="w-full flex flex-row items-center justify-center gap-x-5 md:gap-x-10 mb-2 font-bold uppercase whitespace-nowrap">
    <router-link to="/" :class="`hidden md:block opacity-30 pb-2`">Skelly.Cards</router-link> 
    <router-link to="/" :class="`${this.$route.path == '/' ? 'text-red-500' : 'opacity-60 hover:opacity-90'} pb-2`">Deka-Dice Giveaway</router-link> 
    <router-link to="/about" :class="`${this.$route.path == '/about' ? 'text-red-500' : 'opacity-60 hover:opacity-90'} pb-2`">Projects</router-link>
    <router-link v-if="false" to="/shops"  :class="`${this.$route.path == '/shops' ? 'text-red-500' : 'opacity-60 hover:opacity-90'} pb-2`">Shop Owners</router-link> 
  </div>
  <router-view />
  <div class="flex flex-col md:flex-row justify-center items-start w-full mt-24 mx-auto">

    <div class="flex w-full md:w-auto flex-col text-xm mt-12 text-center md:text-right text-xs gap-y-2">
      <a :class="`text-red-500`" href="https://www.kickstarter.com/projects/skellypacks/deka-dice" target="_blank">Deka-Dice Kickstarter</a> 
      <a class="text-red-500" href="mailto:skellypacks@gmail.com">SkellyPacks@gmail.com</a>
      <a class="text-red-500" href="https://www.youtube.com/@SkellyPacks" target="_blank">YouTube.com/@SkellyPacks</a>
      <a class="text-red-500" href="https://www.youtube.com/@SkellyXP" target="_blank">YouTube.com/@SkellyXP</a>
      <a class="text-red-500" href="https://www.facebook.com/SkellyPacks" target="_blank">FaceBook/SkellyPacks</a>
      <router-link to="/about" :class="`text-red-500`">Projects/About</router-link> 
    </div>
    <iframe v-if="false" width="540" height="505" 
      src="https://9dddd217.sibforms.com/serve/MUIFAOxFFazHpnG2f5RTBe19uki40C8tB70T8chm2QT2s6rGrtLAccghfQ4fSpnJ9Tp7YeW3-97T29VLpgUe0bODYQb1B6-Wd3Xm31lN7WPPITUhEBnQOgSh0V_fYY6R4bSWyAGxaGgTGJiSIjiVJRpRmrmoPCeQenyrtQ1jstjgv-IuNEX7W_Z2rTB0AC_TgwBZcRBaxlYlFubf" frameborder="0" scrolling="auto" allowfullscreen 
      style="display: block;max-width: 100%;"></iframe>
    
    </div>
  </template>

<script>
export default {
  name: 'App'
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>