<template>
<div class="w-full justify-center mx-auto flex flex-col md:flex-row max-w-screen-xl">    
    <div @click="openKickstarter()" class="md:hidden text-center mx-auto p-3 text-lg rounded-lg cursor-pointer bg-green-500 hover:bg-green-400 duration-150 text-white"
        style="text-shadow: -1px -1px 0 #444, 1px -1px 0 #444, -1px 1px 0 #444, 1px 1px 0 #444;">
        <div class="text-3xl font-bold">Back on Kickstarter!</div>
        <div class="font-bold uppercase">Campaign runs October 1st - November 1st</div>
      </div>
    <iframe class="flex-shrink-0" width="640" height="1575" src="https://9dddd217.sibforms.com/serve/MUIFALs-wPt0mz3DyZCo_QudI_-e3HPtgMRI3HOFehQ73yhmxFTgRQl01MkVDNwDG5V61kGaQCkD9PnCCecMDWfnsry0koWPxDloA6ax1oxB-yfCIIULRMBcATJCAopUwBhHp9eHaNN9TVl3iAql_1NsMOpXUVWDi4BsUUg5953szA2oPsCz9HkE4cRn7RdI9JdxFGzXirmOiaoE" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
    <div class="hidden md:block flex flex-col mt-12 gap-y-5">
      <div @click="openKickstarter()" class="text-center mx-auto p-3 text-lg rounded-lg cursor-pointer bg-green-500 hover:bg-green-400 duration-150 text-white"
        style="text-shadow: -1px -1px 0 #444, 1px -1px 0 #444, -1px 1px 0 #444, 1px 1px 0 #444;">
        <div class="text-3xl font-bold">Back on Kickstarter!</div>
        <div class="font-bold uppercase">Campaign runs October 1st - November 1st</div>
      </div>
      <div class="w-5/6 mx-auto py-4">DEKA-DICE: D20 Deck - Use it like a standard set of D20 dice or add an optional gameplay twist to your favorite tabletop games! <br /><a target="_blank" href="https://www.kickstarter.com/projects/skellypacks/deka-dice" class="text-green-400 underline" >Back now on Kickstarter</a>!</div>
        
    <div class="aspect-video rounded-lg overflow-hidden">
        <iframe
        class="w-full h-full"
          height="auto"
          :src="`https://www.youtube.com/embed/g3K3s1GmUgs`"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <a href="https://www.kickstarter.com/projects/skellypacks/deka-dice" target="_blank"><img class="cursor-pointer w-full md:w-full border-white border" src="../assets/img/deka-flips.gif"></a>
      <a href="https://www.kickstarter.com/projects/skellypacks/deka-dice" target="_blank"><img class="cursor-pointer w-full md:w-full border-white border" src="../assets/img/deka-demo.jpg"></a>
      <a href="https://www.kickstarter.com/projects/skellypacks/deka-dice" target="_blank"><img class="cursor-pointer w-full md:w-full border-white border" src="../assets/img/deka-contents.gif"></a>
    </div>
</div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  },
  methods: {
    openKickstarter(){
      window.open('https://www.kickstarter.com/projects/skellypacks/deka-dice')
    }

  }
}
</script>
